var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-content",
    [
      _c(
        "v-toolbar",
        {
          staticClass: "landing-toolbar",
          staticStyle: { "z-index": "2" },
          attrs: { fixed: "" }
        },
        [
          _c(
            "v-layout",
            { attrs: { row: "", wrap: "" } },
            [
              _c(
                "v-flex",
                [
                  _c(
                    "v-container",
                    { attrs: { "py-0": "" } },
                    [
                      _c(
                        "v-layout",
                        { attrs: { row: "", wrap: "" } },
                        [
                          _c(
                            "v-flex",
                            { attrs: { xs12: "", lg10: "", xl9: "" } },
                            [
                              _c(
                                "v-layout",
                                { attrs: { row: "", "align-center": "" } },
                                [
                                  _vm.isBasic || _vm.isColabelled
                                    ? _c(
                                        "v-flex",
                                        {
                                          staticClass: "d-flex",
                                          attrs: { shrink: "" }
                                        },
                                        [
                                          _vm.partner.logo.linkUrl
                                            ? [
                                                _c(
                                                  "a",
                                                  {
                                                    staticClass: "d-flex",
                                                    attrs: {
                                                      href:
                                                        _vm.partner.logo
                                                          .linkUrl,
                                                      target: "_blank"
                                                    }
                                                  },
                                                  [
                                                    _c("img", {
                                                      staticStyle: {
                                                        height: "40px"
                                                      },
                                                      style: {
                                                        "max-width":
                                                          _vm.partnerLogoWidth
                                                      },
                                                      attrs: {
                                                        src:
                                                          _vm.partner.logo
                                                            .imageUrl,
                                                        alt: ""
                                                      }
                                                    })
                                                  ]
                                                )
                                              ]
                                            : [
                                                _c("img", {
                                                  staticClass: "d-flex",
                                                  staticStyle: {
                                                    height: "40px"
                                                  },
                                                  style: {
                                                    "max-width":
                                                      _vm.partnerLogoWidth
                                                  },
                                                  attrs: {
                                                    src:
                                                      _vm.partner.logo.imageUrl,
                                                    alt: ""
                                                  }
                                                })
                                              ]
                                        ],
                                        2
                                      )
                                    : _vm._e(),
                                  _vm.isCobranded
                                    ? [
                                        _c(
                                          "v-flex",
                                          {
                                            staticClass: "d-flex",
                                            attrs: { shrink: "" }
                                          },
                                          [
                                            _c("img", {
                                              staticStyle: {
                                                height: "40px",
                                                "max-width": "100px"
                                              },
                                              attrs: {
                                                src:
                                                  _vm.partner.theme.images
                                                    .MEMBER_DEFAULT.imageUrl,
                                                alt: ""
                                              }
                                            })
                                          ]
                                        ),
                                        _vm.partner.logo &&
                                        !_vm.partner.logo.default
                                          ? [
                                              _c(
                                                "v-flex",
                                                {
                                                  attrs: {
                                                    shrink: "",
                                                    "mx-2": ""
                                                  }
                                                },
                                                [
                                                  _c("v-divider", {
                                                    staticClass: "d-block ma-0",
                                                    staticStyle: {
                                                      height: "34px"
                                                    },
                                                    attrs: {
                                                      vertical: "",
                                                      inset: "",
                                                      color: "darkgrey"
                                                    }
                                                  })
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-flex",
                                                { attrs: { shrink: "" } },
                                                [
                                                  _vm.partner.logo.linkUrl
                                                    ? [
                                                        _c(
                                                          "a",
                                                          {
                                                            staticClass:
                                                              "d-flex",
                                                            attrs: {
                                                              href:
                                                                _vm.partner.logo
                                                                  .linkUrl,
                                                              target: "_blank"
                                                            }
                                                          },
                                                          [
                                                            _c("img", {
                                                              staticStyle: {
                                                                height: "40px"
                                                              },
                                                              style: {
                                                                "max-width":
                                                                  _vm.partnerLogoWidth
                                                              },
                                                              attrs: {
                                                                src:
                                                                  _vm.partner
                                                                    .logo
                                                                    .imageUrl,
                                                                alt: ""
                                                              }
                                                            })
                                                          ]
                                                        )
                                                      ]
                                                    : [
                                                        _c("img", {
                                                          staticClass: "d-flex",
                                                          staticStyle: {
                                                            height: "40px"
                                                          },
                                                          style: {
                                                            "max-width":
                                                              _vm.partnerLogoWidth
                                                          },
                                                          attrs: {
                                                            src:
                                                              _vm.partner.logo
                                                                .imageUrl,
                                                            alt: ""
                                                          }
                                                        })
                                                      ]
                                                ],
                                                2
                                              )
                                            ]
                                          : _vm._e()
                                      ]
                                    : _vm._e(),
                                  _c("v-spacer"),
                                  _c(
                                    "v-flex",
                                    { staticClass: "shrink" },
                                    [
                                      _c("localeSwitcher", {
                                        staticStyle: {
                                          float: "right",
                                          "text-align": "end"
                                        },
                                        attrs: {
                                          "text-class": "primary--text",
                                          "position-class":
                                            "d-flex justify-right"
                                        },
                                        on: {
                                          input: _vm.refreshChildComponents
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                2
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "landing-content fill-height",
          class: {
            mobile: _vm.$vuetify.breakpoint.smAndDown,
            "with-footer": _vm.showFooter
          }
        },
        [
          _c("router-view", {
            key: _vm.refreshKey,
            staticClass: "white",
            attrs: {
              elevation: _vm.$vuetify.breakpoint.xsOnly ? 0 : 8,
              "card-height": 500,
              "card-styles": _vm.$vuetify.breakpoint.xsOnly
                ? "margin-top:90px;"
                : ""
            }
          })
        ],
        1
      ),
      _vm.showFooter
        ? _c(
            "v-footer",
            {
              staticClass: "landing-footer",
              style: "background-image: url('" + _vm.backgroundUrl + "');",
              attrs: { absolute: "" }
            },
            [
              _c(
                "v-layout",
                {
                  attrs: {
                    column: "",
                    "fill-height": "",
                    "pa-3": "",
                    "pb-2": ""
                  }
                },
                [
                  _c(
                    "v-flex",
                    [
                      _c(
                        "v-layout",
                        { attrs: { row: "", wrap: "" } },
                        [
                          _vm.partner && _vm.partner.type === "COLABELLED"
                            ? _c(
                                "v-flex",
                                {
                                  attrs: {
                                    xs12: "",
                                    "text-center": "",
                                    "mb-2": ""
                                  }
                                },
                                [
                                  _c(
                                    "p",
                                    {
                                      staticClass: "starling-body text-italic",
                                      staticStyle: { color: "white!important" }
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$t(
                                              "common.partnership.powered_by"
                                            )
                                          ) +
                                          " "
                                      )
                                    ]
                                  ),
                                  _c("starling-logo", {
                                    staticStyle: { "max-width": "200px" }
                                  })
                                ],
                                1
                              )
                            : _vm._e(),
                          _c(
                            "v-flex",
                            {
                              class: {
                                "py-2":
                                  !_vm.partner ||
                                  _vm.partner.type !== "COLABELLED"
                              },
                              attrs: { xs12: "", "text-center": "", "mb-2": "" }
                            },
                            [
                              _c(
                                "h2",
                                { staticClass: "starling-h2 white--text" },
                                [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(
                                        _vm.$t(
                                          "public.landing.content.having_questions.title"
                                        )
                                      ) +
                                      "\n            "
                                  )
                                ]
                              )
                            ]
                          ),
                          _c(
                            "v-flex",
                            { attrs: { xs12: "", "text-center": "" } },
                            [
                              _c("div", {
                                staticClass: "starling-body white--text",
                                domProps: {
                                  innerHTML: _vm._s(
                                    _vm.$t(
                                      "public.landing.content.having_questions.description"
                                    )
                                  )
                                }
                              })
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c("v-spacer", { staticClass: "fill-height" }),
                  _vm.maintenance
                    ? _c("maintenance-bar", { attrs: { rounded: "" } })
                    : _vm._e(),
                  _c("v-flex", { attrs: { "text-center": "" } }, [
                    _c("p", { staticClass: "caption white--text mb-0" }, [
                      _vm._v(
                        "© " +
                          _vm._s(new Date().getFullYear()) +
                          " Starling Minds™ Inc."
                      )
                    ])
                  ])
                ],
                1
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }